import {
  GenreDataApiResponse,
  TownsDataApiResponse,
} from "@/interface/ApiInterface"

import { classInfo } from "@/app/baseInfoSlice"
import makeApi from "./connect"

const api = makeApi(import.meta.env.VITE_API_BASE_URL)

export const getRegions = (): Promise<{ regions: string[]; years: number[] }> =>
  api.get("regions")

export const getDefaultKpi = () => api.get("default")

export const getRegion = (_region: string, _year: number) =>
  api.get("data", {
    params: {
      region: _region,
      year: _year,
    },
  })

export const getDefault = (open_file_name: string) =>
  api.get("getDefault", { params: { open_file_name: open_file_name } })

export const getVaule = (
  open_file_name: string,
  region: string,
  year: number,
) =>
  api.get("getRegion", {
    params: { open_file_name: open_file_name, region: region, year: year },
  })

export const getClassInfo = (
  open_file_name: string,
): Promise<{ res: classInfo }> =>
  api.get("getClassInfo", {
    params: { open_file_name: open_file_name },
  })

interface regyear {
  res: [string[], string[]]
}
export const getRegYear = (open_file_name: string): Promise<{ res: regyear }> =>
  api.get("getRegYear", { params: { open_file_name: open_file_name } })

export const getLang = (open_file_name: string) =>
  api.get("getLang", { params: { open_file_name: open_file_name } })

export const changeLang = (open_file_name: string, lang: string) =>
  api.get("changeLang", {
    params: { open_file_name: open_file_name, lang: lang },
  })

// ====================================================
// ====================================================
export const getPlaces = (): Promise<{ latLng: number[][] }> =>
  api.get("places")

export const getCompanies = (): Promise<{ res: string[] }> =>
  api.get("companies")

export const getLayerData = (): Promise<{ res: unknown }> =>
  api.get("layer-data")

export const getAllGenres = (): Promise<{
  res: GenreDataApiResponse
}> => api.get("genres")

export const getAllTownsData = (): Promise<{ res: TownsDataApiResponse }> =>
  api.get("towns-data")

export const searchPlace = (
  category: string,
  name: string,
): Promise<{ res: unknown }> =>
  api.get("search-place", {
    params: {
      category: category,
      name: name,
    },
  })
