import { Suspense, useState } from "react"
import { Toaster } from "react-hot-toast"

import RenderRouter from "@/routes"

import { PageLoader } from "./features/loader/Loader"

import "App.css"

function App() {
  return (
    <Suspense fallback={<PageLoader />}>
      <RenderRouter />
      <Toaster />
    </Suspense>
  )
}

export default App
