import { Skeleton } from "@mui/material"
import { styled } from "styled-components"

const SelectRadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 10px;
  min-height: 45px;
  /* z-index: 15; */
  /* background: #343434; */
`

const LoadingSkeleton = () => {
  return (
    <SelectRadioContainer>
      <Skeleton
        variant="rounded"
        width={"100%"}
        height={"100%"}
        sx={{ bgcolor: "grey.900" }}
      />
    </SelectRadioContainer>
  )
}

export default LoadingSkeleton
