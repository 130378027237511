import RowItem from "@/features/list/rowItem"
import { createSlice } from "@reduxjs/toolkit"

export interface RowItemInfo {
  id: number
  region: string
  year: number
  color: string
}

interface ListContents {
  listContents: RowItemInfo[]
}

const initialState: ListContents = {
  listContents: [
    {
      id: 1,
      region: "Taipei City",
      year: 2010,
      color: "Orange",
    },
  ],
}

const listSlice = createSlice({
  name: "listSlice",
  initialState: initialState,
  reducers: {
    initListContents(state) {
      //init
      // let tmp = []
      state.listContents = []
      // console.log("================")

      // console.log(state.listContents)
    },
    addRowItem(state, action) {
      let tmp = state.listContents.slice()
      tmp.splice(state.listContents.length, 0, action.payload)
      // console.log(action.payload)
      state.listContents = tmp
    },

    updateRowItem(state, action) {
      state.listContents[action.payload[0]] = action.payload[1]
    },

    delRowItem(state, action) {
      if (state.listContents.length <= 0) return
      // state.kpiInfoArray.datasets.splice(action.payload, 1)
      state.listContents = state.listContents.filter(
        (item, index) => index != action.payload,
      )
    },
  },
})

export const { initListContents, addRowItem, delRowItem, updateRowItem } =
  listSlice.actions

export default listSlice.reducer
