import axios from "axios"

export default function connect(baseURL: string) {
  const instance = axios.create({
    baseURL,
  })

  instance.interceptors.response.use(
    (response) => response.data,
    (error) => Promise.reject(error),
  )

  return instance
}
