import { ViewState } from "react-map-gl"

export const ClusterCode = {
  White: {
    point: [255, 255, 255],
    line: [255, 255, 255, 30],
  },
  Red: {
    point: [169, 50, 38],
    line: [205, 97, 85, 30],
  },
  Purple: {
    point: [92, 43, 152, 50],
    line: [92, 43, 152, 30],
  },
  Blue: {
    point: [46, 134, 193],
    line: [84, 153, 199, 30],
  },
  "L Green": {
    point: [150, 242, 215],
    line: [145, 251, 195, 30],
  },
  Green: {
    point: [23, 154, 122],
    line: [23, 154, 122, 100],
  },
  Yellow: {
    point: [212, 172, 13],
    line: [244, 208, 63, 30],
  },
  Orange: {
    point: [202, 111, 30],
    line: [235, 152, 78, 30],
  },
  "L Grey": {
    point: [166, 172, 175],
    line: [202, 207, 210, 30],
  },
  "D Grey": {
    point: [131, 145, 146],
    line: [170, 183, 184, 30],
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any

export const PlaceCode = {
  0: {
    point: [169, 50, 38],
    line: [205, 97, 85, 30],
  },
  1: {
    point: [136, 78, 160],
    line: [175, 122, 197, 30],
  },
  2: {
    point: [92, 43, 152, 30],
    line: [92, 43, 152, 30],
  },
  3: {
    point: [23, 165, 137],
    line: [72, 201, 176, 30],
  },
  4: {
    point: [34, 153, 84],
    line: [82, 190, 128, 30],
  },
  5: {
    point: [23, 154, 122],
    line: [23, 154, 122, 100],
  },
  6: {
    point: [202, 111, 30],
    line: [235, 152, 78, 30],
  },
  7: {
    point: [166, 172, 175],
    line: [202, 207, 210, 30],
  },
  8: {
    point: [131, 145, 146],
    line: [170, 183, 184, 30],
  },
}

// Set your mapbox access token here
export const MAPBOX_ACCESS_TOKEN = import.meta.env.VITE_MAPBOX_TOKEN

// init ViewPort setting
export const INITIAL_VIEW_STATE: Partial<ViewState> = {
  longitude: 121.55759,
  latitude: 25.04257,
  zoom: 13.5,
  pitch: 0,
  bearing: 0,
}

export const PaintLayer = {
  "fill-extrusion-color": "#9ed7f8",
  "fill-extrusion-height": {
    type: "identity" as const,
    property: "height",
  },
  "fill-extrusion-base": {
    type: "id0.0ty" as "identity",
    property: "min_height",
  },
  "fill-extrusion-opacity": 0.6,
} as const

export const mapStyles = {
  bulePrint: import.meta.env.VITE_MAPBOX_STYLE,
} as const
