import { SagaIterator } from "@redux-saga/core"
import { call, delay, put, takeEvery, takeLatest } from "redux-saga/effects"
import { notify } from "@/utils/notify"

import { getClassInfo, getLang, getRegYear, getVaule } from "@/api/get"
import { uploadFile } from "@/api/post"

import {
  addDataset,
  updateDataset,
  delDataset,
  clearDataset,
} from "../kpiSlice"
import {
  addRowItem,
  updateRowItem,
  delRowItem,
  initListContents,
} from "../listSlice"

import {
  setClass,
  setIsUpload,
  setLangList,
  setRegYear,
  setReloading,
  radarAction,
} from "@/app/baseInfoSlice"
import { RowItemInfo } from "@/features/list/selectList"
import { createAction } from "@reduxjs/toolkit"

export function* onUploadFile({
  payload,
}: {
  type: typeof radarAction.UploadFile
  payload: { formData: FormData }
}): SagaIterator {
  try {
    const respoense = yield call(uploadFile, payload.formData)
    console.log(respoense)
    if (respoense.status == 1) {
      //init data
      yield put(clearDataset())
      yield put(initListContents())
      // yield call(delay, 300)

      const res1 = yield call(getClassInfo, respoense.fileName)
      const res2 = yield call(getRegYear, respoense.fileName)
      const res3 = yield call(getLang, respoense.fileName)
      //call api
      // console.log(res1)
      // console.log(res2)
      // console.log(res3)
      yield put(setIsUpload(respoense.fileName))
      yield put(setClass(res1.data))
      yield put(setRegYear(res2.data))
      yield put(setLangList(res3.data))
      // call reducer
      notify("Upload data success")
    }
  } catch (error) {
    notify("Upload data failed, data structure may be WRONG")
    console.log("POST error")
  }
}

export function* onAsyncAdd({
  payload,
}: {
  type: typeof radarAction.AsyncAdd
  payload: { fileName: string; info: RowItemInfo }
}): SagaIterator {
  const res = yield call(
    getVaule,
    payload.fileName,
    payload.info.region,
    payload.info.year,
  )

  yield put(addRowItem(payload.info))
  //
  yield put(
    addDataset({
      region: String(payload.info.region),
      year: Number(payload.info.year),
      score: res.data,
      color: "Orange",
    }),
  )
}

export function* onAsyncUpdata({
  payload,
}: {
  type: typeof radarAction.AsyncUpdata
  payload: { fileName: string; info: RowItemInfo; index: number }
}): SagaIterator {
  const { data } = yield call(
    getVaule,
    payload.fileName,
    payload.info.region,
    payload.info.year,
  )

  yield put(updateRowItem([payload.index, payload.info]))
  yield put(
    updateDataset([
      payload.index + 1,
      {
        region: payload.info.region,
        year: payload.info.year,
        score: data,
        color: payload.info.color,
      },
    ]),
  )
}

export function* onAsyncGetClassInfo({
  payload,
}: {
  type: typeof radarAction.getClassInfo
  payload: { fileName: string }
}): SagaIterator {
  const { data } = yield call(getClassInfo, payload.fileName)
  // console.log(data)

  yield put(setClass(data))
}

export function* onAsyncGetRegYear({
  payload,
}: {
  type: typeof radarAction.getRegYear
  payload: { fileName: string }
}): SagaIterator {
  const { data } = yield call(getRegYear, payload.fileName)
  //call api

  yield put(setRegYear(data))

  // call reducer
}

export function* onAsyncGetLang({
  payload,
}: {
  type: typeof radarAction.getGetLang
  payload: { fileName: string }
}): SagaIterator {
  const { data } = yield call(getLang, payload.fileName)
  //call api

  yield put(setLangList(data))
  // call reducer
}

// Watcher Saga
export function* watchRadarSaga(): SagaIterator {
  yield takeEvery(radarAction.UploadFile.type, onUploadFile)
  yield takeLatest(radarAction.AsyncAdd.type, onAsyncAdd)
  // yield takeEvery(radarAction.AsyncAdd.type, onAsyncAdd)
  yield takeEvery(radarAction.AsyncUpdata.type, onAsyncUpdata)

  yield takeEvery(radarAction.getClassInfo.type, onAsyncGetClassInfo)
  yield takeEvery(radarAction.getRegYear.type, onAsyncGetRegYear)
  yield takeEvery(radarAction.getGetLang.type, onAsyncGetLang)
}

export default watchRadarSaga
