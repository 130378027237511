import { SagaIterator } from "@redux-saga/core"
import { call, put, takeEvery } from "redux-saga/effects"

import { getLayerData, getPlaces, searchPlace, getCompanies } from "@/api/get"
import { INITIAL_VIEW_STATE } from "@/constants"
import { notify } from "@/utils/notify"

import {
  mapAction,
  // setIsClickZoomout,
  setIsLayerDataFetching,
  setIsSearchingPlace,
  setIsTypingSearchBar,
  setLayerData,
  setPlaceData,
  setCompanyData,
  setViewState,
  // setZoomoutLatlngDeck,
  addPinItem,
} from "../mapSlice"

export function* onGetPlaceData(): SagaIterator {
  const { res } = yield call(getPlaces)
  // console.log(res)
  yield put(setPlaceData(res))
}

export function* onGetCompanyData(): SagaIterator {
  const { res } = yield call(getCompanies)
  // console.log(res)
  yield put(setCompanyData(res))
}

export function* onGetLayerData(): SagaIterator {
  yield put(setIsLayerDataFetching(true))
  // call reducer
  const { res } = yield call(getLayerData)
  //call api

  // console.log(res)
  yield put(setLayerData(res))
  yield put(setIsLayerDataFetching(false))
}

export function* onSearchPlace({
  payload,
}: {
  type: typeof mapAction.saerchPlaceSaga
  payload: { category: string; name: string }
}): SagaIterator {
  yield put(setIsSearchingPlace(true))

  const { res } = yield call(searchPlace, payload.category, payload.name)
  try {
    if (res !== "404") {
      const zoom = 16
      const pitch = 60
      const [longitude, latitude, name, address] = res
      yield put(
        setViewState({
          ...INITIAL_VIEW_STATE,
          zoom,
          longitude,
          latitude,
          pitch,
        }),
      )
      yield put(
        addPinItem({
          lnglat: [longitude, latitude],
          name: name,
          address: address,
        }),
      )
      // should be add.....
      // yield put(setIsClickZoomout(true))
      yield put(setIsSearchingPlace(false))
      yield put(setIsTypingSearchBar(false))
      notify("Search success")
    }
  } catch {
    notify("Place not found")
    yield put(setIsSearchingPlace(false))
  }
  // else {
  //   notify("Place not found")
  //   yield put(setIsSearchingPlace(false))
  // }
}

// Watcher Saga
export function* mapWatcherSaga(): SagaIterator {
  // yield takeEvery(mapAction.getGenreDataSaga.type, onGetGenreData)
  yield takeEvery(mapAction.getPlaceDataSaga.type, onGetPlaceData)
  yield takeEvery(mapAction.getCompanyDataSaga.type, onGetCompanyData)

  yield takeEvery(mapAction.getLayerDataSaga.type, onGetLayerData)
  yield takeEvery(mapAction.saerchPlaceSaga.type, onSearchPlace)
}

export default mapWatcherSaga
