import React, { FC } from "react"
import { RouteObject, useRoutes } from "react-router-dom"

const MapPage = React.lazy(() => import("@/pages/MapPage"))
const RadarChartPage = React.lazy(() => import("@/pages/RadarChartPage"))
import MainPage from "@/pages/Main/main"

const routeList: RouteObject[] = [
  {
    path: "/",
    element: <RadarChartPage />,
  },
  {
    path: "/map",
    element: <MapPage />,
  },
  {
    path: "/test",
    element: <MainPage />,
  },
]

const RenderRouter: FC = () => {
  const element = useRoutes(routeList)

  return element
  // return <div style={{ height: 933, width: 1848 }}>{element}</div>
}

export default RenderRouter
