import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
  currentLanguage: "en" | "zh"
}

const initialState: State = {
  currentLanguage: "en",
}

const systemSlice = createSlice({
  name: "systemSlice",
  initialState: initialState,
  reducers: {
    setCurrentLanguage(state, action: PayloadAction<"en" | "zh">) {
      state.currentLanguage = action.payload
    },
  },
})

export const { setCurrentLanguage } = systemSlice.actions

export default systemSlice.reducer
