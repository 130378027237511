import { createSlice } from "@reduxjs/toolkit"

interface KpiInfo {
  region: string
  year: number
  score: number[]
  color: string
}

interface KpiArray {
  kpiInfoArray: {
    datasets: KpiInfo[]
  }
}

const initialState: KpiArray = {
  kpiInfoArray: {
    datasets: [
      {
        region: "-1",
        year: 0,
        score: [],
        color: "Orange",
      },
      {
        region: "Taipei City",
        year: 2010,
        score: [
          -0.68, -0.46, 0.08, -0.85, -0.41, -0.85, -1.09, -0.14, -0.79, -0.8,
          -1.09, -1.08, 0.85, -1, -0.74, -1.13, 0.85, 1.73, -0.65, -0.47, -0.3,
          -0.3, -0.25, -0.26, -0.61, -0.79, -1.43, 0.17, 0.16, -0.19, 0.76,
          -0.83, -0.62, -1.67, -0.23, -1.55, -1.52, -0.28, -0.31, 0.41, -1.09,
          -0.09, -0.52, -0.22, -0.1, -0.54, -0.39, -0.39, -0.67, -0.57, -0.96,
          1.3, 0.66, 1.93, 1.95, 0.27, -0.11, 3.07, -0.29, 0.79, -0.24, -0.45,
          0.41, 0.15, 0.87, -0.28, 0.05, -0.8,
        ],
        color: "Orange",
      },
    ],
  },
}

const kpiSlice = createSlice({
  name: "kpiSlice",
  initialState: initialState,
  reducers: {
    initDataset(state, action) {
      state.kpiInfoArray.datasets = action.payload
      // console.log(action.payload)
    },
    addDataset(state, action) {
      let tmp = state.kpiInfoArray.datasets.slice()
      tmp.splice(state.kpiInfoArray.datasets.length, 0, action.payload)
      // console.log(action.payload)
      state.kpiInfoArray.datasets = tmp
    },

    updateDataset(state, action) {
      state.kpiInfoArray.datasets[action.payload[0]] = action.payload[1]
    },

    delDataset(state, action) {
      if (state.kpiInfoArray.datasets.length <= 0) return
      // state.kpiInfoArray.datasets.splice(action.payload, 1)
      state.kpiInfoArray.datasets = state.kpiInfoArray.datasets.filter(
        (item, index) => index != action.payload,
      )
    },

    clearDataset(state) {
      // let tmp = state.kpiInfoArray.datasets.splice(
      //   2,
      //   state.kpiInfoArray.datasets.length - 1,
      // )
      state.kpiInfoArray.datasets = [
        {
          region: "-1",
          year: 0,
          score: [],
          color: "Orange",
        },
      ]
      // console.log(state.kpiInfoArray.datasets)
      // only keep init datasets
    },

    updateColor(state, action) {
      state.kpiInfoArray.datasets[action.payload[0]].color = action.payload[1]
    },
  },
})

export const {
  initDataset,
  addDataset,
  updateDataset,
  delDataset,
  clearDataset,
  updateColor,
} = kpiSlice.actions

export default kpiSlice.reducer
