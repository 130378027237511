import { FC } from "react"
import styled, { keyframes } from "styled-components"

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

const PageSpinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 60px;
  height: 60px;
  border-radius: 50%;
`

const Container = styled.div`
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`

const MapContainer = styled("div")<{ showsidebar: boolean }>`
  position: relative;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  opacity: 0.4;
  gap: 40px;
  z-index: 99;
  ${(props) => (props.showsidebar ? `width: 80vw;` : undefined)};
`

const MapLoadingText = styled.div`
  color: white;
`

export default Spinner

export const PageLoader = () => {
  return (
    <Container>
      <PageSpinner />
    </Container>
  )
}

export const MapLoader: FC<{ showsidebar: boolean }> = ({ showsidebar }) => {
  return (
    <MapContainer showsidebar={showsidebar}>
      <PageSpinner />
      <MapLoadingText>Loading</MapLoadingText>
    </MapContainer>
  )
}
