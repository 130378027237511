import { createAction, createSlice } from "@reduxjs/toolkit"
import { RowItemInfo } from "./listSlice"

export interface classInfo {
  class1_name: string[]
  class1_subclass_number: number[]
  class2_name: string[]
  class2_subclass_number: number[]
  kpi_name: string[]
}
interface baseInfo {
  classInfo: classInfo
  langInfo: string[]
  regionInfo: string[]
  yearInfo: number[]
  isUpload: boolean
  reLoading: boolean
  fileName: string
}

const initialState = {
  classInfo: {
    class1_name: ["DEVELOPING STRATEGY", "DESIGN ACTIVITY", "DESIGN EFFECT"],
    class1_subclass_number: [7, 4, 7],
    class2_name: [
      "Entrepreneurial Support",
      "Cultural Support",
      "Design Support",
      "Design Bid",
      "Human Development",
      "R&D Resources",
      "Design Infrastructure",
      "Industry Size",
      "Entrepreneurial Vitality",
      "Creative Class",
      "Intellectual Property",
      "Industrial Output Value",
      "Public Consumption",
      "City Branding",
      "Public Participation",
      "Public Discussion",
      "Public Appreciation",
      "Environmental Influences",
    ],
    class2_subclass_number: [
      2, 3, 9, 2, 7, 1, 9, 4, 5, 5, 4, 7, 1, 1, 5, 1, 1, 1,
    ],
    kpi_name: [
      "Number of incubators/accelerators",
      "Young entrepreneur financing loan credit guarantees - number of households",
      "Number of  manpower of government cultural agencies",
      "Substitute military service of department of cultural affairs",
      "Number of cultural volunteers",
      "Number of events in cultural & creative industries park",
      "Number of visitors in cultural & creative industries park",
      "Number of public art installation",
      "Spent on public art installation",
      "Ratio of cultural expenditure to total local government budget",
      "Cultural expenditure budget",
      "Budget of department of cultural affairs",
      "Budget execution rate of department of cultural affairs",
      "The ratio of budget of cultural department to the total budget of local government",
      "Number of design-related public construction bids",
      "Number of design-related non-public construction bids",
      "Hours of talent cultivation by cultural department",
      "Number of talent cultivation by cultural department",
      "Number of colleges and universities with design departments",
      "Number of teachers and staffs in design departments",
      "Number of students in design departments",
      "Number of design graduates",
      "Number of graduates with a bachelor's degree or above",
      "Young entrepreneur financing loan credit guarantees - financing amount",
      "Number of museums",
      "Number of local culture center",
      "Number of libraries and archives",
      "Number of arts spaces",
      "Number of other cultural exhibition venues",
      "Number of parks, zoos and gardens and tourist attractions",
      "Number of community activity centers",
      "Number of Art District",
      "Amount of subsidy for community empowerment by ministry of culture",
      "Number of design service companies",
      "Number of companies in cultural and creative industries",
      "Number of arts and cultural groups",
      "Number of arts and culture foundations",
      "Capital of newly established design companies",
      "Number of start-up companies",
      "Number of events by foreign art groups or individuals",
      "Number of audiences attending events by foreign art groups or individuals",
      "Social innovation enterprise business service area",
      "Number of designers",
      "Number of in-house designers",
      "Number of freelance designers",
      "Ratio of design-related job vacancies to the total job vacancies",
      "Number of design-related job postings",
      "Number of  design patents issued",
      "Number of invention patent applications",
      "Number of applications",
      "Proportion of applications by females",
      "Contribution of design industry to GDP",
      "Design industry turnover",
      "Annual growth rate of design industry turnover",
      "Average production value per designer",
      "Cultural and creative industry foreign sales",
      "Turnover of cultural and creative industries",
      "Annual growth rate of cultural and creative industry turnover",
      "Cultural activities participation rate",
      "Number of design awards received",
      "Number of art exhibitions and performances",
      "Number of attendees of arts exhibitions and performances",
      "Number of important art festivals",
      "Number of days of important art festivals",
      "Number of visitors of important art festivals",
      "Online discussions about design events",
      "Praise to design events",
      "Number of buildings certified as green buildings",
    ],
  },
  langInfo: [],
  regionInfo: [
    "New Taipei City",
    "Taoyuan City",
    "Taichung City",
    "Taipei City",
    "Tainan City",
    "Kaohsiung City",
    "Nantou County",
    "Keelung City",
    "Yilan County",
    "Pingtung County",
    "Changhua County",
    "Hsinchu City",
    "Hsinchu County",
    "Miaoli County",
    "Yunlin County",
    "Chiayi City",
    "Chiayi County",
    "Penghu County",
    "Taitung County",
    "Hualien County",
    "Lienchiang County",
    "Kinmen County",
  ],
  yearInfo: [
    2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021,
    2022,
  ],
  isUpload: false,
  reLoading: false,
  fileName: "",
} as baseInfo

const BaseInfoSlice = createSlice({
  name: "BaseInfoSlice",
  initialState: initialState,
  reducers: {
    setClass(state, action) {
      const [
        class1_subclass_number,
        class2_subclass_number,
        class1_name,
        class2_name,
        kpi_name,
      ] = action.payload
      state.classInfo.class1_name = class1_name
      state.classInfo.class1_subclass_number = class1_subclass_number
      state.classInfo.class2_name = class2_name
      state.classInfo.class2_subclass_number = class2_subclass_number
      state.classInfo.kpi_name = kpi_name
    },
    setRegYear(state, action) {
      const [regionInfo, yearInfo] = action.payload
      state.regionInfo = regionInfo
      state.yearInfo = yearInfo
    },
    setIsUpload(state, action) {
      state.isUpload = true
      // console.log(action.payload)
      state.fileName = action.payload
    },
    setLangList(state, action) {
      state.langInfo = action.payload
    },
    setReloading(state, action) {
      state.reLoading = action.payload
    },
  },
})

export const radarAction = {
  // getGenreDataSaga: createAction(`${mapSlice.name}/getGenreData`),
  UploadFile: createAction<{ formData: FormData }>(
    `${BaseInfoSlice.name}/Upload`,
  ),
  AsyncAdd: createAction<{ fileName: string; info: RowItemInfo }>(
    `${BaseInfoSlice.name}/AsyncAdd`,
  ),
  AsyncUpdata: createAction<{
    fileName: string
    info: RowItemInfo
    index: number
  }>(`${BaseInfoSlice.name}/AsyncUpdata`),

  getClassInfo: createAction<{ fileName: string }>(
    `${BaseInfoSlice.name}/AsyncGetClassInfo`,
  ),
  getRegYear: createAction<{ fileName: string }>(
    `${BaseInfoSlice.name}/AsyncGetRegYear`,
  ),
  getGetLang: createAction<{ fileName: string }>(
    `${BaseInfoSlice.name}/onAsyncGetLang`,
  ),
}

export const { setClass, setRegYear, setIsUpload, setLangList, setReloading } =
  BaseInfoSlice.actions

export default BaseInfoSlice.reducer
