/* eslint-disable @typescript-eslint/no-explicit-any */
import { ViewState } from "react-map-gl"
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
// import randomColor from "randomcolor"

import { INITIAL_VIEW_STATE } from "@/constants"

// import { GenreData } from "@/interface/ApiInterface"
import { Dict } from "styled-components/dist/types"

interface PinInfo {
  lnglat: [number, number]
  name: string
  adress: string
}

interface State {
  hoverInfo: any
  placeData: Dict[]
  companyData: Dict[]
  layerStatus: boolean
  selectedCategoryList: string[]

  viewState: Partial<ViewState>

  pinArray: PinInfo[]

  // isClickZoomout: boolean
  isSearchingPlace: boolean
  layerData: any[]
  // currentSelectLayerData: any[]
  isLayerDataFetching: boolean
  isTypingSearchBar: boolean
}

const initialState: State = {
  hoverInfo: [undefined],
  placeData: [],
  companyData: [],
  layerStatus: true,
  selectedCategoryList: [],

  viewState: INITIAL_VIEW_STATE,
  pinArray: [],
  layerData: [],

  // currentSelectLayerData: [],
  // isClickZoomout: false,
  isSearchingPlace: false,
  isLayerDataFetching: true,
  isTypingSearchBar: true,
}

export enum ChangeSelectType {
  INSTITUTE,
  COMPANY,
}

const mapSlice = createSlice({
  name: "mapSlice",
  initialState: initialState,
  reducers: {
    setHoverInfo(state, action: PayloadAction<any>) {
      if (action.payload !== undefined) {
        const allHoverInfo = state.layerData.filter((data) => {
          return (
            data.lnglat[0] === action.payload.lnglat[0] &&
            data.lnglat[1] === action.payload.lnglat[1] &&
            state.selectedCategoryList.includes(data.category)
          )
        })
        state.hoverInfo = allHoverInfo
      } else {
        state.hoverInfo = [action.payload]
      }
    },

    setLayerStatus(state, action) {
      state.layerStatus = action.payload
    },
    setPlaceData(state, action) {
      state.placeData = action.payload
    },
    setCompanyData(state, action) {
      state.companyData = action.payload
    },
    addSelectedCategory(state, action) {
      state.selectedCategoryList = [
        ...state.selectedCategoryList,
        action.payload,
      ]
    },
    removeSelectedCategory(state, action) {
      state.selectedCategoryList = state.selectedCategoryList.filter(
        (Category) => Category != action.payload,
      )
    },
    updatePointColor(state, action) {
      // const { category, color } = action.payload
      const tmp = state.layerData.map((obj, index) => {
        if (obj.category == action.payload[0]) {
          return { ...obj, color: action.payload[1] }
        }
        return obj
      })
      // console.log(tmp)
      state.layerData = tmp
    },

    setViewState(state, action: PayloadAction<Partial<ViewState>>) {
      state.viewState = action.payload
    },

    addPinItem(state, action) {
      let tmp = state.pinArray.slice()
      tmp.splice(state.pinArray.length, 0, action.payload)
      state.pinArray = tmp
    },
    removePinItem(state, action) {
      if (state.pinArray.length <= 0) return
      state.pinArray = state.pinArray.filter(
        (item, index) => item.name != action.payload,
      )
    },

    // setIsClickZoomout(state, action: PayloadAction<boolean>) {
    //   state.isClickZoomout = action.payload
    // },

    setIsSearchingPlace(state, action: PayloadAction<boolean>) {
      state.isSearchingPlace = action.payload
    },

    // from api request
    setLayerData(state, action: PayloadAction<any>) {
      // const tmp = state.layerData.map()
      // console.log(action)
      state.layerData = action.payload
      // state.currentSelectLayerData = action.payload
    },
    setIsLayerDataFetching(state, action: PayloadAction<boolean>) {
      state.isLayerDataFetching = action.payload
    },
    setIsTypingSearchBar(state, action: PayloadAction<boolean>) {
      state.isTypingSearchBar = action.payload
    },
  },
})

// Actions
export const mapAction = {
  // getGenreDataSaga: createAction(`${mapSlice.name}/getGenreData`),
  getPlaceDataSaga: createAction(`${mapSlice.name}/getPlaceData`),
  getCompanyDataSaga: createAction(`${mapSlice.name}/getCompanyData`),

  getLayerDataSaga: createAction(`${mapSlice.name}/getLayerData`),
  saerchPlaceSaga: createAction<{ category: string; name: string }>(
    `${mapSlice.name}/saerchPlace`,
  ),
}

export const {
  setHoverInfo,
  setLayerStatus,
  addSelectedCategory,
  removeSelectedCategory,
  setViewState,
  setPlaceData,
  setCompanyData,

  updatePointColor,
  // setZoomoutLatlngDeck,
  addPinItem,
  removePinItem,

  // setIsClickZoomout,

  setIsSearchingPlace,
  setLayerData,
  setIsLayerDataFetching,
  setIsTypingSearchBar,
} = mapSlice.actions

export default mapSlice.reducer
