// import Grid from "@mui/material/Grid"

// import DashBoard from "@/features/dashborad /dashboard"
import LoadingSkeleton from "@/features/loader/LoadingSkeleton"

const MainPage = () => {
  return (
    <>
      {/* <DashBoard size={100} /> */}
      {/* <div style={{ color: "red" }}>hello</div> */}
      <LoadingSkeleton />
    </>
  )
}

export default MainPage
