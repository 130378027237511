import createSagaMiddleware from "@redux-saga/core"
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import logger from "redux-logger"

import rootSaga from "./sagas/root-saga"
import BaseInfoSlice from "./baseInfoSlice"
import kpiSlice from "./kpiSlice"
import mapSlice from "./mapSlice"
import systemSlice from "./systemSlice"
import listSlice from "./listSlice"

const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware()

  const store = configureStore({
    reducer: {
      getKpi: kpiSlice,
      getMap: mapSlice,
      getBaseInfo: BaseInfoSlice,
      getListContents: listSlice,
      system: systemSlice,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
        sagaMiddleware,
      ),
    // make sure uncomment redux-logger in prod
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // .concat(logger as any),
  })

  sagaMiddleware.run(rootSaga)

  return store
}

export const store = makeStore()

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
