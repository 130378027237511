import { all, fork } from "redux-saga/effects"

import { mapWatcherSaga } from "./map-saga"
import { watchRadarSaga } from "./radar-saga"

export function* rootSaga() {
  yield all([fork(mapWatcherSaga)])
  yield all([fork(watchRadarSaga)])
}

export default rootSaga
